import {Module} from 'vuex'

import UTMObject from '@/models/utm'
import axios from '@/plugins/axios'
import moment from '@/plugins/moment'

import BrowserStorage from '@/utils/browser-storage'

export const DefaultCurrencies: any = {
  GB: 'gbp',
  IE: 'eur',
}

export const Currencies: any = {
  gbp: {
    label: '£ GBP',
    iso: 'gbp',
  },
  eur: {
    label: '€ EUR',
    iso: 'eur',
  },
}

const DefaultCurrency = Currencies.gbp
const VERSION = 1.0

let localStorageGlobal = BrowserStorage.restore('global')
if (!localStorageGlobal.updated_at || localStorageGlobal.version !== VERSION) {
  localStorageGlobal = {}
} else if (moment().diff(localStorageGlobal.updated_at, 'minutes') > 1440) {
  delete localStorageGlobal.region
}

const initialState: any = {
  region: localStorageGlobal.region || null,
  currency: localStorageGlobal.currency || DefaultCurrency,
  utm: null,
  entry_url: null,
}

const saveToLocalStorage = (state: any) => {
  state.updated_at = moment()
  state.version = VERSION
  BrowserStorage.save('global', state)
}

export const global: Module<any, any> = {
  namespaced: true,
  getters: {
    region: state => state.region,
    country: state => state.region?.country_code || 'GB',
    currency: state => state.currency.iso,
  },
  state: initialState,
  actions: {
    async fetchRegion({commit}) {
      try {
        const {data} = await axios.get('https://ipapi.co/json')

        commit('SET_REGION', data)

        if (data.currency.toLowerCase() in Currencies) {
          commit('SET_CURRENCY', Currencies[data.currency.toLowerCase()])
        }
      } catch (err) {
        commit('SET_CURRENCY', Currencies.gbp)
        // Sentry.setExtra('context', 'could not retrieve ipapi.co')
        // Sentry.captureException(err)
      }
    },

    async setCurrency({commit}, currency: string) {
      currency = currency.toLowerCase()
      if (currency in Currencies) {
        commit('SET_CURRENCY', Currencies[currency])
      }
    },

    async setUTM({commit}, utm: UTMObject) {
      commit('SET_UTM', utm)
    },

    async setEntryUrl({commit}, url: string) {
      commit('SET_ENTRY_URL', url)
    },
  },
  mutations: {
    SET_REGION(state, region) {
      state.region = region
      saveToLocalStorage(state)
    },

    SET_CURRENCY(state, currency: any) {
      state.currency = currency
      saveToLocalStorage(state)
    },

    SET_UTM(state, utm: UTMObject) {
      state.utm = utm
    },

    SET_ENTRY_URL(state, url: string) {
      state.entry_url = url
    },
  },
}
