export class Config {
  get() {
    return {
      domain: 'adventure001.ie',
      title: 'Adventure 001',
      currency: 'eur',
      map: {
        zoom: 10,
        center: {
          lat: 53.934927,
          lng: -2.407734,
        },
        bounds: [
          {lng: -11.0303, lat: 49.838},
          {lng: 2.0654, lat: 59.6677},
        ],
      },
      destinations: {
        maximum: 2,
      },
      passengers: {
        min: 1,
        max: 10,
      },
      costs: {
        show_vat: false,
        minimum: {
          default: 1250,
        },
      },

      routeTypes: {
        tour: {
          link: {
            href: 'https://www.adventure001.ie/Gift-Ideas/Pleasure-Flights',
            text: 'View Scheduled Tours from €39',
          },
        },
      },

      links: {
        brochure: {
          href: '/irl/brochure.pdf',
          text: 'Brochure &amp; Contact',
        },
        instagram: {
          href: 'https://instagram.com/adventure001i',
          icon: ['fab', 'instagram'],
          text: 'instagram',
        },
      },
    }
  }
}
