export class Config {
  get() {
    return {
      domain: 'helifuelservices.co.uk/',
      title: 'Helifuel Services',
      currency: 'gbp',
      map: {
        zoom: 10,
        center: {
          lat: 53.934927,
          lng: -2.407734,
        },
        bounds: [
          {lng: -11.0303, lat: 49.838},
          {lng: 2.0654, lat: 59.6677},
        ],
      },
      destinations: {
        maximum: 2,
      },
      passengers: {
        min: 1,
        max: 10,
      },
      costs: {
        minimum: {
          default: 1250,
        },
      },
    }
  }
}
