export class Config {
  get() {
    return {
      domain: 'hampshire-helicopters.co.uk',
      title: 'Hampshire Helicopters',
      map: {
        zoom: 10,
        center: {
          lat: 55.0338821,
          lng: -1.7296158,
        },
      },
      destinations: {
        maximum: 2,
      },
      passengers: {
        min: 1,
        max: 10,
      },
      costs: {
        minimum: 1250,
      },

      routeTypes: {
        tour: {
          link: {
            href: 'https://www.adventure001.com/Activity/Aviation/Helicopter-Rides/43',
            text: 'View Scheduled Tours from £39',
          },
        },
      },

      links: {
        brochure: {
          href: '/adventure001/brochure.pdf',
          text: 'Brochure &amp; Contact',
        },
        instagram: {
          href: 'https://facebook.com/hampshirehelicopters',
          icon: ['fab', 'facebook'],
          text: 'facebook',
        },
      },
    }
  }
}
