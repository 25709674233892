export class Config {
  get() {
    return {
      domain: 'left.aero',
      title: 'Leading Edge',
      currency: 'gbp',
      map: {
        zoom: 10,
        center: {
          lat: 55.874695,
          lng: -4.438775,
        },
        bounds: [
          {lng: -11.0303, lat: 49.838},
          {lng: 2.0654, lat: 59.6677},
        ],
      },
      destinations: {
        maximum: 2,
      },
      passengers: {
        min: 1,
        max: 10,
      },
      costs: {
        minimum: {
          default: 1250,
        },
      },
    }
  }
}
