import * as Sentry from '@sentry/vue'

export default class BrandProvider {
  static brand: string

  static get isValid(): boolean {
    return !!this.get()
  }

  static get(identify: boolean = true): string {
    if (!this.brand && identify) {
      const brand = this.identify()
      this.set(brand)
    }

    return this.brand
  }

  static config(): any {
    const {Config} = require(`@/config/brands/${this.brand}/config.ts`)

    return new Config().get()
  }

  static set(brand: string): void {
    if (brand === 'adventure_001') {
      brand = 'adventure001'
    }

    if (this.valid(brand)) {
      this.brand = brand
    }
  }

  static valid(brand: string): boolean {
    try {
      require(`@/config/brands/${brand}/config.ts`)

      return true
    } catch (err) {
      Sentry.setExtra('brand', brand)
      Sentry.captureMessage('Brand is not valid', 'warning')
    }

    return false
  }

  static styles(): void {
    import(`@/config/brands/${this.get()}/application.scss`)
  }

  private static identify(): string {
    const queryBrand = new URLSearchParams(window.location.search).get('brand')

    if (queryBrand !== null) {
      return queryBrand.replace(/_/g, '')
    }

    return process.env.BRAND_NAME || 'adventure001'
  }
}
